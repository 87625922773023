import React from "react";
import Typography from "@mui/material/Typography";

export default function SpecialistLendingThankYou() {
  return (
    <>
      <Typography paragraph={true}>
        Thanks for contacting us! We will get in touch with you shortly.
      </Typography>
    </>
  );
}
